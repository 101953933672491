.others{
    box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.3);
    z-index: 100;
    padding: 15px;
    text-align: left;
    display: block;
    background-color: #fff;
    border-radius: 7px;
    border: 0.1px solid;
    position: fixed;
    top: 50;
    right: 10px;
    width: 200px;
    p {
        font-size: 1rem;
        font-weight: 600;
    }
    .icons{
        margin-top: 20px;
        display: flex;
        .icon{
            cursor: pointer;
            margin-right: 20px;
            img {
                width: auto;
                height: 40px;
            }
            p {
                margin-left: 5px;
                font-size: 0.7rem;
                font-weight: 100;
            }
        }
    }
    
}