.header{
    background-color: #f5f5f7;
    overflow: hidden;
    height: 30px;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    position: relative;
    .headerItems {
        display: flex;
        justify-content: space-between;
        .logo {
            display: flex;
            img {
                margin-top: 5px;
                margin-right: 10px;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
            p {
                margin-top: 2.5px;
                color: #ffab15;
                cursor: default;
            }
        }
        .moreMenu{
            button {
                margin-top: 1px;
                border: none;
                background: none;
                font-size: 1.6rem;
                color: #6e6e73;
                cursor: pointer;
            }
        }
    }
    hr {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        opacity: 0.5;
    }
}