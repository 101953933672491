.noteLists {
    background-color: #f2f2f7;
    width: 270px;
    height: calc(100% - 15px);
    padding-bottom: 15px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    @media (max-width: 767px) {
        width: 100vw;
    }
    .noteListsHeader {
        width: 100%;
        height: 40px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        div {
            display: flex;
            h3{
                font-size: 1.5rem;
                color: #000;
                margin-top: 7px;
            }
        }
        button{
            display: block;
            color: #ffab15;
            font-size: 1.8rem;
            background: none;
            border: none;
            margin: 0 15px;
            margin-top: 5px;
        }
        .backBtn {
            margin: 0 5px;
            margin-top: 5px;
        }
        @media (min-width: 767px) {
            display: none;
        }
    }
    h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #9d9da3;
        @media (max-width: 767px) {
           font-size: 1.3rem;
        }
    }
    .searchBar {
        background-color: #fff;
        width: calc(100% - 30px);      
        display: flex;
        border-radius: 8px;
        margin: 10px auto;
        overflow: hidden;
        @media (max-width: 767px) {
            font-size: 1.1rem;
        }
        .searchBarIcon {
            display: block;
            font-size: 1.5rem;
            margin-top: 8px;
            margin-left: 8px;
        }
        input {
            width: calc(100% - 35px);
            height: 30px;
            padding: 5px;
            padding-right: 10px;
            outline: none;
            font-size: 1rem;
        }
    }
    .notes {
        width: calc(100% - 30px);
        height: calc(100% - 60px);
        @media (max-width: 767px) {
            height: calc(100% - 100px);
        }
        border-radius: 7px;
        margin: 0 15px;
        padding-bottom: 15px;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        .noteListItem{
            background-color: #fff;
            padding-top: 10px;
            &:last-child {
                border-radius: 0 0 7px 7px;
                hr {
                    display: none;
                }
            }
            h4 {
                margin: 0 10px;
                padding-bottom: 10px;
                font-size: 1rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media (max-width: 767px) {
                    font-size: 1.2rem;
                }
    
            }
            .noteBody {
                padding-left: 10px;
                font-size: 0.9rem;
                font-weight: 300;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;  
                overflow: hidden;
                @media (max-width: 767px) {
                    font-size: 1rem;
                }
            }
            .noteDate {
                padding: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 0.8rem;
            }
            hr{
                opacity: 0.3;
            }
        }
        .noteListItemActive {
            background-color: rgb(255,220,64,0.3);
            hr {
                display: none;
            }
        }
    }
    .noteListsDivider {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        opacity: 0.3;
    }
 
} 